/* tslint:disable */
// eslint-disable-next-line no-unused-vars
import * as runtime from './runtime';
// eslint-disable-next-line no-unused-vars
import { Api, ApiInterface } from './apis';
// eslint-disable-next-line no-unused-vars
import * as Model from './models';
// eslint-disable-next-line no-unused-vars
import * as CommentApi from './apis/CommentApi';
// eslint-disable-next-line no-unused-vars
import * as ContentApi from './apis/ContentApi';
// eslint-disable-next-line no-unused-vars
import * as CreditApi from './apis/CreditApi';
// eslint-disable-next-line no-unused-vars
import * as IdeaApi from './apis/IdeaApi';
// eslint-disable-next-line no-unused-vars
import * as NotificationApi from './apis/NotificationApi';
// eslint-disable-next-line no-unused-vars
import * as ProjectApi from './apis/ProjectApi';
// eslint-disable-next-line no-unused-vars
import * as UserApi from './apis/UserApi';
// eslint-disable-next-line no-unused-vars
import * as VoteApi from './apis/VoteApi';

export * from './runtime';
export * from './apis';
export * from './models';

export enum Action {
  commentCreate = 'commentCreate',
  commentDelete = 'commentDelete',
  commentSearch = 'commentSearch',
  commentUpdate = 'commentUpdate',
  ideaCommentSearch = 'ideaCommentSearch',
  contentUpload = 'contentUpload',
  transactionSearch = 'transactionSearch',
  ideaCreate = 'ideaCreate',
  ideaDelete = 'ideaDelete',
  ideaGet = 'ideaGet',
  ideaGetAll = 'ideaGetAll',
  ideaMerge = 'ideaMerge',
  ideaSearch = 'ideaSearch',
  ideaUpdate = 'ideaUpdate',
  notificationClear = 'notificationClear',
  notificationClearAll = 'notificationClearAll',
  notificationSearch = 'notificationSearch',
  configAndUserBindSlug = 'configAndUserBindSlug',
  configBindSlug = 'configBindSlug',
  userBindSlug = 'userBindSlug',
  forgotPassword = 'forgotPassword',
  userBind = 'userBind',
  userCreate = 'userCreate',
  userDelete = 'userDelete',
  userGet = 'userGet',
  userLogin = 'userLogin',
  userLogout = 'userLogout',
  userUpdate = 'userUpdate',
  categorySubscribe = 'categorySubscribe',
  commentVoteGetOwn = 'commentVoteGetOwn',
  commentVoteUpdate = 'commentVoteUpdate',
  ideaVoteGetOwn = 'ideaVoteGetOwn',
  ideaVoteUpdate = 'ideaVoteUpdate',
}


export type Actions = 
  commentCreateActionFulfilled
  | commentCreateActionPending
  | commentCreateActionRejected
  | commentDeleteActionFulfilled
  | commentDeleteActionPending
  | commentDeleteActionRejected
  | commentSearchActionFulfilled
  | commentSearchActionPending
  | commentSearchActionRejected
  | commentUpdateActionFulfilled
  | commentUpdateActionPending
  | commentUpdateActionRejected
  | ideaCommentSearchActionFulfilled
  | ideaCommentSearchActionPending
  | ideaCommentSearchActionRejected
  | contentUploadActionFulfilled
  | contentUploadActionPending
  | contentUploadActionRejected
  | transactionSearchActionFulfilled
  | transactionSearchActionPending
  | transactionSearchActionRejected
  | ideaCreateActionFulfilled
  | ideaCreateActionPending
  | ideaCreateActionRejected
  | ideaDeleteActionFulfilled
  | ideaDeleteActionPending
  | ideaDeleteActionRejected
  | ideaGetActionFulfilled
  | ideaGetActionPending
  | ideaGetActionRejected
  | ideaGetAllActionFulfilled
  | ideaGetAllActionPending
  | ideaGetAllActionRejected
  | ideaMergeActionFulfilled
  | ideaMergeActionPending
  | ideaMergeActionRejected
  | ideaSearchActionFulfilled
  | ideaSearchActionPending
  | ideaSearchActionRejected
  | ideaUpdateActionFulfilled
  | ideaUpdateActionPending
  | ideaUpdateActionRejected
  | notificationClearActionFulfilled
  | notificationClearActionPending
  | notificationClearActionRejected
  | notificationClearAllActionFulfilled
  | notificationClearAllActionPending
  | notificationClearAllActionRejected
  | notificationSearchActionFulfilled
  | notificationSearchActionPending
  | notificationSearchActionRejected
  | configAndUserBindSlugActionFulfilled
  | configAndUserBindSlugActionPending
  | configAndUserBindSlugActionRejected
  | configBindSlugActionFulfilled
  | configBindSlugActionPending
  | configBindSlugActionRejected
  | userBindSlugActionFulfilled
  | userBindSlugActionPending
  | userBindSlugActionRejected
  | forgotPasswordActionFulfilled
  | forgotPasswordActionPending
  | forgotPasswordActionRejected
  | userBindActionFulfilled
  | userBindActionPending
  | userBindActionRejected
  | userCreateActionFulfilled
  | userCreateActionPending
  | userCreateActionRejected
  | userDeleteActionFulfilled
  | userDeleteActionPending
  | userDeleteActionRejected
  | userGetActionFulfilled
  | userGetActionPending
  | userGetActionRejected
  | userLoginActionFulfilled
  | userLoginActionPending
  | userLoginActionRejected
  | userLogoutActionFulfilled
  | userLogoutActionPending
  | userLogoutActionRejected
  | userUpdateActionFulfilled
  | userUpdateActionPending
  | userUpdateActionRejected
  | categorySubscribeActionFulfilled
  | categorySubscribeActionPending
  | categorySubscribeActionRejected
  | commentVoteGetOwnActionFulfilled
  | commentVoteGetOwnActionPending
  | commentVoteGetOwnActionRejected
  | commentVoteUpdateActionFulfilled
  | commentVoteUpdateActionPending
  | commentVoteUpdateActionRejected
  | ideaVoteGetOwnActionFulfilled
  | ideaVoteGetOwnActionPending
  | ideaVoteGetOwnActionRejected
  | ideaVoteUpdateActionFulfilled
  | ideaVoteUpdateActionPending
  | ideaVoteUpdateActionRejected
;



export enum commentCreateActionStatus {
  Pending = 'commentCreate_PENDING',
  Fulfilled = 'commentCreate_FULFILLED',
  Rejected = 'commentCreate_REJECTED',
}
export interface commentCreateActionFulfilled {
  type: commentCreateActionStatus.Fulfilled;
  meta: { action: Action.commentCreate; request: CommentApi.CommentCreateRequest; };
  payload: Model.CommentWithVote;
}
export interface commentCreateActionPending {
  type: commentCreateActionStatus.Pending;
  meta: { action: Action.commentCreate; request: CommentApi.CommentCreateRequest; };
}
export interface commentCreateActionRejected {
  type: commentCreateActionStatus.Rejected;
  meta: { action: Action.commentCreate; request: CommentApi.CommentCreateRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentDeleteActionStatus {
  Pending = 'commentDelete_PENDING',
  Fulfilled = 'commentDelete_FULFILLED',
  Rejected = 'commentDelete_REJECTED',
}
export interface commentDeleteActionFulfilled {
  type: commentDeleteActionStatus.Fulfilled;
  meta: { action: Action.commentDelete; request: CommentApi.CommentDeleteRequest; };
  payload: Model.Comment;
}
export interface commentDeleteActionPending {
  type: commentDeleteActionStatus.Pending;
  meta: { action: Action.commentDelete; request: CommentApi.CommentDeleteRequest; };
}
export interface commentDeleteActionRejected {
  type: commentDeleteActionStatus.Rejected;
  meta: { action: Action.commentDelete; request: CommentApi.CommentDeleteRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentSearchActionStatus {
  Pending = 'commentSearch_PENDING',
  Fulfilled = 'commentSearch_FULFILLED',
  Rejected = 'commentSearch_REJECTED',
}
export interface commentSearchActionFulfilled {
  type: commentSearchActionStatus.Fulfilled;
  meta: { action: Action.commentSearch; request: CommentApi.CommentSearchRequest; };
  payload: Model.CommentSearchResponse;
}
export interface commentSearchActionPending {
  type: commentSearchActionStatus.Pending;
  meta: { action: Action.commentSearch; request: CommentApi.CommentSearchRequest; };
}
export interface commentSearchActionRejected {
  type: commentSearchActionStatus.Rejected;
  meta: { action: Action.commentSearch; request: CommentApi.CommentSearchRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentUpdateActionStatus {
  Pending = 'commentUpdate_PENDING',
  Fulfilled = 'commentUpdate_FULFILLED',
  Rejected = 'commentUpdate_REJECTED',
}
export interface commentUpdateActionFulfilled {
  type: commentUpdateActionStatus.Fulfilled;
  meta: { action: Action.commentUpdate; request: CommentApi.CommentUpdateRequest; };
  payload: Model.Comment;
}
export interface commentUpdateActionPending {
  type: commentUpdateActionStatus.Pending;
  meta: { action: Action.commentUpdate; request: CommentApi.CommentUpdateRequest; };
}
export interface commentUpdateActionRejected {
  type: commentUpdateActionStatus.Rejected;
  meta: { action: Action.commentUpdate; request: CommentApi.CommentUpdateRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaCommentSearchActionStatus {
  Pending = 'ideaCommentSearch_PENDING',
  Fulfilled = 'ideaCommentSearch_FULFILLED',
  Rejected = 'ideaCommentSearch_REJECTED',
}
export interface ideaCommentSearchActionFulfilled {
  type: ideaCommentSearchActionStatus.Fulfilled;
  meta: { action: Action.ideaCommentSearch; request: CommentApi.IdeaCommentSearchRequest; };
  payload: Model.IdeaCommentSearchResponse;
}
export interface ideaCommentSearchActionPending {
  type: ideaCommentSearchActionStatus.Pending;
  meta: { action: Action.ideaCommentSearch; request: CommentApi.IdeaCommentSearchRequest; };
}
export interface ideaCommentSearchActionRejected {
  type: ideaCommentSearchActionStatus.Rejected;
  meta: { action: Action.ideaCommentSearch; request: CommentApi.IdeaCommentSearchRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum contentUploadActionStatus {
  Pending = 'contentUpload_PENDING',
  Fulfilled = 'contentUpload_FULFILLED',
  Rejected = 'contentUpload_REJECTED',
}
export interface contentUploadActionFulfilled {
  type: contentUploadActionStatus.Fulfilled;
  meta: { action: Action.contentUpload; request: ContentApi.ContentUploadRequest; };
  payload: Model.ContentUploadResponse;
}
export interface contentUploadActionPending {
  type: contentUploadActionStatus.Pending;
  meta: { action: Action.contentUpload; request: ContentApi.ContentUploadRequest; };
}
export interface contentUploadActionRejected {
  type: contentUploadActionStatus.Rejected;
  meta: { action: Action.contentUpload; request: ContentApi.ContentUploadRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum transactionSearchActionStatus {
  Pending = 'transactionSearch_PENDING',
  Fulfilled = 'transactionSearch_FULFILLED',
  Rejected = 'transactionSearch_REJECTED',
}
export interface transactionSearchActionFulfilled {
  type: transactionSearchActionStatus.Fulfilled;
  meta: { action: Action.transactionSearch; request: CreditApi.TransactionSearchRequest; };
  payload: Model.TransactionSearchResponse;
}
export interface transactionSearchActionPending {
  type: transactionSearchActionStatus.Pending;
  meta: { action: Action.transactionSearch; request: CreditApi.TransactionSearchRequest; };
}
export interface transactionSearchActionRejected {
  type: transactionSearchActionStatus.Rejected;
  meta: { action: Action.transactionSearch; request: CreditApi.TransactionSearchRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaCreateActionStatus {
  Pending = 'ideaCreate_PENDING',
  Fulfilled = 'ideaCreate_FULFILLED',
  Rejected = 'ideaCreate_REJECTED',
}
export interface ideaCreateActionFulfilled {
  type: ideaCreateActionStatus.Fulfilled;
  meta: { action: Action.ideaCreate; request: IdeaApi.IdeaCreateRequest; };
  payload: Model.IdeaWithVote;
}
export interface ideaCreateActionPending {
  type: ideaCreateActionStatus.Pending;
  meta: { action: Action.ideaCreate; request: IdeaApi.IdeaCreateRequest; };
}
export interface ideaCreateActionRejected {
  type: ideaCreateActionStatus.Rejected;
  meta: { action: Action.ideaCreate; request: IdeaApi.IdeaCreateRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaDeleteActionStatus {
  Pending = 'ideaDelete_PENDING',
  Fulfilled = 'ideaDelete_FULFILLED',
  Rejected = 'ideaDelete_REJECTED',
}
export interface ideaDeleteActionFulfilled {
  type: ideaDeleteActionStatus.Fulfilled;
  meta: { action: Action.ideaDelete; request: IdeaApi.IdeaDeleteRequest; };
  payload: void;
}
export interface ideaDeleteActionPending {
  type: ideaDeleteActionStatus.Pending;
  meta: { action: Action.ideaDelete; request: IdeaApi.IdeaDeleteRequest; };
}
export interface ideaDeleteActionRejected {
  type: ideaDeleteActionStatus.Rejected;
  meta: { action: Action.ideaDelete; request: IdeaApi.IdeaDeleteRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaGetActionStatus {
  Pending = 'ideaGet_PENDING',
  Fulfilled = 'ideaGet_FULFILLED',
  Rejected = 'ideaGet_REJECTED',
}
export interface ideaGetActionFulfilled {
  type: ideaGetActionStatus.Fulfilled;
  meta: { action: Action.ideaGet; request: IdeaApi.IdeaGetRequest; };
  payload: Model.IdeaWithVote;
}
export interface ideaGetActionPending {
  type: ideaGetActionStatus.Pending;
  meta: { action: Action.ideaGet; request: IdeaApi.IdeaGetRequest; };
}
export interface ideaGetActionRejected {
  type: ideaGetActionStatus.Rejected;
  meta: { action: Action.ideaGet; request: IdeaApi.IdeaGetRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaGetAllActionStatus {
  Pending = 'ideaGetAll_PENDING',
  Fulfilled = 'ideaGetAll_FULFILLED',
  Rejected = 'ideaGetAll_REJECTED',
}
export interface ideaGetAllActionFulfilled {
  type: ideaGetAllActionStatus.Fulfilled;
  meta: { action: Action.ideaGetAll; request: IdeaApi.IdeaGetAllRequest; };
  payload: Model.IdeaGetAllResponse;
}
export interface ideaGetAllActionPending {
  type: ideaGetAllActionStatus.Pending;
  meta: { action: Action.ideaGetAll; request: IdeaApi.IdeaGetAllRequest; };
}
export interface ideaGetAllActionRejected {
  type: ideaGetAllActionStatus.Rejected;
  meta: { action: Action.ideaGetAll; request: IdeaApi.IdeaGetAllRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaMergeActionStatus {
  Pending = 'ideaMerge_PENDING',
  Fulfilled = 'ideaMerge_FULFILLED',
  Rejected = 'ideaMerge_REJECTED',
}
export interface ideaMergeActionFulfilled {
  type: ideaMergeActionStatus.Fulfilled;
  meta: { action: Action.ideaMerge; request: IdeaApi.IdeaMergeRequest; };
  payload: Model.IdeaConnectResponse;
}
export interface ideaMergeActionPending {
  type: ideaMergeActionStatus.Pending;
  meta: { action: Action.ideaMerge; request: IdeaApi.IdeaMergeRequest; };
}
export interface ideaMergeActionRejected {
  type: ideaMergeActionStatus.Rejected;
  meta: { action: Action.ideaMerge; request: IdeaApi.IdeaMergeRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaSearchActionStatus {
  Pending = 'ideaSearch_PENDING',
  Fulfilled = 'ideaSearch_FULFILLED',
  Rejected = 'ideaSearch_REJECTED',
}
export interface ideaSearchActionFulfilled {
  type: ideaSearchActionStatus.Fulfilled;
  meta: { action: Action.ideaSearch; request: IdeaApi.IdeaSearchRequest; };
  payload: Model.IdeaWithVoteSearchResponse;
}
export interface ideaSearchActionPending {
  type: ideaSearchActionStatus.Pending;
  meta: { action: Action.ideaSearch; request: IdeaApi.IdeaSearchRequest; };
}
export interface ideaSearchActionRejected {
  type: ideaSearchActionStatus.Rejected;
  meta: { action: Action.ideaSearch; request: IdeaApi.IdeaSearchRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaUpdateActionStatus {
  Pending = 'ideaUpdate_PENDING',
  Fulfilled = 'ideaUpdate_FULFILLED',
  Rejected = 'ideaUpdate_REJECTED',
}
export interface ideaUpdateActionFulfilled {
  type: ideaUpdateActionStatus.Fulfilled;
  meta: { action: Action.ideaUpdate; request: IdeaApi.IdeaUpdateRequest; };
  payload: Model.Idea;
}
export interface ideaUpdateActionPending {
  type: ideaUpdateActionStatus.Pending;
  meta: { action: Action.ideaUpdate; request: IdeaApi.IdeaUpdateRequest; };
}
export interface ideaUpdateActionRejected {
  type: ideaUpdateActionStatus.Rejected;
  meta: { action: Action.ideaUpdate; request: IdeaApi.IdeaUpdateRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum notificationClearActionStatus {
  Pending = 'notificationClear_PENDING',
  Fulfilled = 'notificationClear_FULFILLED',
  Rejected = 'notificationClear_REJECTED',
}
export interface notificationClearActionFulfilled {
  type: notificationClearActionStatus.Fulfilled;
  meta: { action: Action.notificationClear; request: NotificationApi.NotificationClearRequest; };
  payload: void;
}
export interface notificationClearActionPending {
  type: notificationClearActionStatus.Pending;
  meta: { action: Action.notificationClear; request: NotificationApi.NotificationClearRequest; };
}
export interface notificationClearActionRejected {
  type: notificationClearActionStatus.Rejected;
  meta: { action: Action.notificationClear; request: NotificationApi.NotificationClearRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum notificationClearAllActionStatus {
  Pending = 'notificationClearAll_PENDING',
  Fulfilled = 'notificationClearAll_FULFILLED',
  Rejected = 'notificationClearAll_REJECTED',
}
export interface notificationClearAllActionFulfilled {
  type: notificationClearAllActionStatus.Fulfilled;
  meta: { action: Action.notificationClearAll; request: NotificationApi.NotificationClearAllRequest; };
  payload: void;
}
export interface notificationClearAllActionPending {
  type: notificationClearAllActionStatus.Pending;
  meta: { action: Action.notificationClearAll; request: NotificationApi.NotificationClearAllRequest; };
}
export interface notificationClearAllActionRejected {
  type: notificationClearAllActionStatus.Rejected;
  meta: { action: Action.notificationClearAll; request: NotificationApi.NotificationClearAllRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum notificationSearchActionStatus {
  Pending = 'notificationSearch_PENDING',
  Fulfilled = 'notificationSearch_FULFILLED',
  Rejected = 'notificationSearch_REJECTED',
}
export interface notificationSearchActionFulfilled {
  type: notificationSearchActionStatus.Fulfilled;
  meta: { action: Action.notificationSearch; request: NotificationApi.NotificationSearchRequest; };
  payload: Model.NotificationSearchResponse;
}
export interface notificationSearchActionPending {
  type: notificationSearchActionStatus.Pending;
  meta: { action: Action.notificationSearch; request: NotificationApi.NotificationSearchRequest; };
}
export interface notificationSearchActionRejected {
  type: notificationSearchActionStatus.Rejected;
  meta: { action: Action.notificationSearch; request: NotificationApi.NotificationSearchRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum configAndUserBindSlugActionStatus {
  Pending = 'configAndUserBindSlug_PENDING',
  Fulfilled = 'configAndUserBindSlug_FULFILLED',
  Rejected = 'configAndUserBindSlug_REJECTED',
}
export interface configAndUserBindSlugActionFulfilled {
  type: configAndUserBindSlugActionStatus.Fulfilled;
  meta: { action: Action.configAndUserBindSlug; request: ProjectApi.ConfigAndUserBindSlugRequest; };
  payload: Model.ConfigAndUserBindSlugResult;
}
export interface configAndUserBindSlugActionPending {
  type: configAndUserBindSlugActionStatus.Pending;
  meta: { action: Action.configAndUserBindSlug; request: ProjectApi.ConfigAndUserBindSlugRequest; };
}
export interface configAndUserBindSlugActionRejected {
  type: configAndUserBindSlugActionStatus.Rejected;
  meta: { action: Action.configAndUserBindSlug; request: ProjectApi.ConfigAndUserBindSlugRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum configBindSlugActionStatus {
  Pending = 'configBindSlug_PENDING',
  Fulfilled = 'configBindSlug_FULFILLED',
  Rejected = 'configBindSlug_REJECTED',
}
export interface configBindSlugActionFulfilled {
  type: configBindSlugActionStatus.Fulfilled;
  meta: { action: Action.configBindSlug; request: ProjectApi.ConfigBindSlugRequest; };
  payload: Model.ConfigBindSlugResult;
}
export interface configBindSlugActionPending {
  type: configBindSlugActionStatus.Pending;
  meta: { action: Action.configBindSlug; request: ProjectApi.ConfigBindSlugRequest; };
}
export interface configBindSlugActionRejected {
  type: configBindSlugActionStatus.Rejected;
  meta: { action: Action.configBindSlug; request: ProjectApi.ConfigBindSlugRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userBindSlugActionStatus {
  Pending = 'userBindSlug_PENDING',
  Fulfilled = 'userBindSlug_FULFILLED',
  Rejected = 'userBindSlug_REJECTED',
}
export interface userBindSlugActionFulfilled {
  type: userBindSlugActionStatus.Fulfilled;
  meta: { action: Action.userBindSlug; request: ProjectApi.UserBindSlugRequest; };
  payload: Model.UserBindResponse;
}
export interface userBindSlugActionPending {
  type: userBindSlugActionStatus.Pending;
  meta: { action: Action.userBindSlug; request: ProjectApi.UserBindSlugRequest; };
}
export interface userBindSlugActionRejected {
  type: userBindSlugActionStatus.Rejected;
  meta: { action: Action.userBindSlug; request: ProjectApi.UserBindSlugRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum forgotPasswordActionStatus {
  Pending = 'forgotPassword_PENDING',
  Fulfilled = 'forgotPassword_FULFILLED',
  Rejected = 'forgotPassword_REJECTED',
}
export interface forgotPasswordActionFulfilled {
  type: forgotPasswordActionStatus.Fulfilled;
  meta: { action: Action.forgotPassword; request: UserApi.ForgotPasswordRequest; };
  payload: void;
}
export interface forgotPasswordActionPending {
  type: forgotPasswordActionStatus.Pending;
  meta: { action: Action.forgotPassword; request: UserApi.ForgotPasswordRequest; };
}
export interface forgotPasswordActionRejected {
  type: forgotPasswordActionStatus.Rejected;
  meta: { action: Action.forgotPassword; request: UserApi.ForgotPasswordRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userBindActionStatus {
  Pending = 'userBind_PENDING',
  Fulfilled = 'userBind_FULFILLED',
  Rejected = 'userBind_REJECTED',
}
export interface userBindActionFulfilled {
  type: userBindActionStatus.Fulfilled;
  meta: { action: Action.userBind; request: UserApi.UserBindRequest; };
  payload: Model.UserBindResponse;
}
export interface userBindActionPending {
  type: userBindActionStatus.Pending;
  meta: { action: Action.userBind; request: UserApi.UserBindRequest; };
}
export interface userBindActionRejected {
  type: userBindActionStatus.Rejected;
  meta: { action: Action.userBind; request: UserApi.UserBindRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userCreateActionStatus {
  Pending = 'userCreate_PENDING',
  Fulfilled = 'userCreate_FULFILLED',
  Rejected = 'userCreate_REJECTED',
}
export interface userCreateActionFulfilled {
  type: userCreateActionStatus.Fulfilled;
  meta: { action: Action.userCreate; request: UserApi.UserCreateRequest; };
  payload: Model.UserCreateResponse;
}
export interface userCreateActionPending {
  type: userCreateActionStatus.Pending;
  meta: { action: Action.userCreate; request: UserApi.UserCreateRequest; };
}
export interface userCreateActionRejected {
  type: userCreateActionStatus.Rejected;
  meta: { action: Action.userCreate; request: UserApi.UserCreateRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userDeleteActionStatus {
  Pending = 'userDelete_PENDING',
  Fulfilled = 'userDelete_FULFILLED',
  Rejected = 'userDelete_REJECTED',
}
export interface userDeleteActionFulfilled {
  type: userDeleteActionStatus.Fulfilled;
  meta: { action: Action.userDelete; request: UserApi.UserDeleteRequest; };
  payload: void;
}
export interface userDeleteActionPending {
  type: userDeleteActionStatus.Pending;
  meta: { action: Action.userDelete; request: UserApi.UserDeleteRequest; };
}
export interface userDeleteActionRejected {
  type: userDeleteActionStatus.Rejected;
  meta: { action: Action.userDelete; request: UserApi.UserDeleteRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userGetActionStatus {
  Pending = 'userGet_PENDING',
  Fulfilled = 'userGet_FULFILLED',
  Rejected = 'userGet_REJECTED',
}
export interface userGetActionFulfilled {
  type: userGetActionStatus.Fulfilled;
  meta: { action: Action.userGet; request: UserApi.UserGetRequest; };
  payload: Model.User;
}
export interface userGetActionPending {
  type: userGetActionStatus.Pending;
  meta: { action: Action.userGet; request: UserApi.UserGetRequest; };
}
export interface userGetActionRejected {
  type: userGetActionStatus.Rejected;
  meta: { action: Action.userGet; request: UserApi.UserGetRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userLoginActionStatus {
  Pending = 'userLogin_PENDING',
  Fulfilled = 'userLogin_FULFILLED',
  Rejected = 'userLogin_REJECTED',
}
export interface userLoginActionFulfilled {
  type: userLoginActionStatus.Fulfilled;
  meta: { action: Action.userLogin; request: UserApi.UserLoginRequest; };
  payload: Model.UserMeWithBalance;
}
export interface userLoginActionPending {
  type: userLoginActionStatus.Pending;
  meta: { action: Action.userLogin; request: UserApi.UserLoginRequest; };
}
export interface userLoginActionRejected {
  type: userLoginActionStatus.Rejected;
  meta: { action: Action.userLogin; request: UserApi.UserLoginRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userLogoutActionStatus {
  Pending = 'userLogout_PENDING',
  Fulfilled = 'userLogout_FULFILLED',
  Rejected = 'userLogout_REJECTED',
}
export interface userLogoutActionFulfilled {
  type: userLogoutActionStatus.Fulfilled;
  meta: { action: Action.userLogout; request: UserApi.UserLogoutRequest; };
  payload: void;
}
export interface userLogoutActionPending {
  type: userLogoutActionStatus.Pending;
  meta: { action: Action.userLogout; request: UserApi.UserLogoutRequest; };
}
export interface userLogoutActionRejected {
  type: userLogoutActionStatus.Rejected;
  meta: { action: Action.userLogout; request: UserApi.UserLogoutRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum userUpdateActionStatus {
  Pending = 'userUpdate_PENDING',
  Fulfilled = 'userUpdate_FULFILLED',
  Rejected = 'userUpdate_REJECTED',
}
export interface userUpdateActionFulfilled {
  type: userUpdateActionStatus.Fulfilled;
  meta: { action: Action.userUpdate; request: UserApi.UserUpdateRequest; };
  payload: Model.UserMe;
}
export interface userUpdateActionPending {
  type: userUpdateActionStatus.Pending;
  meta: { action: Action.userUpdate; request: UserApi.UserUpdateRequest; };
}
export interface userUpdateActionRejected {
  type: userUpdateActionStatus.Rejected;
  meta: { action: Action.userUpdate; request: UserApi.UserUpdateRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum categorySubscribeActionStatus {
  Pending = 'categorySubscribe_PENDING',
  Fulfilled = 'categorySubscribe_FULFILLED',
  Rejected = 'categorySubscribe_REJECTED',
}
export interface categorySubscribeActionFulfilled {
  type: categorySubscribeActionStatus.Fulfilled;
  meta: { action: Action.categorySubscribe; request: VoteApi.CategorySubscribeRequest; };
  payload: Model.UserMe;
}
export interface categorySubscribeActionPending {
  type: categorySubscribeActionStatus.Pending;
  meta: { action: Action.categorySubscribe; request: VoteApi.CategorySubscribeRequest; };
}
export interface categorySubscribeActionRejected {
  type: categorySubscribeActionStatus.Rejected;
  meta: { action: Action.categorySubscribe; request: VoteApi.CategorySubscribeRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentVoteGetOwnActionStatus {
  Pending = 'commentVoteGetOwn_PENDING',
  Fulfilled = 'commentVoteGetOwn_FULFILLED',
  Rejected = 'commentVoteGetOwn_REJECTED',
}
export interface commentVoteGetOwnActionFulfilled {
  type: commentVoteGetOwnActionStatus.Fulfilled;
  meta: { action: Action.commentVoteGetOwn; request: VoteApi.CommentVoteGetOwnRequest; };
  payload: Model.CommentVoteGetOwnResponse;
}
export interface commentVoteGetOwnActionPending {
  type: commentVoteGetOwnActionStatus.Pending;
  meta: { action: Action.commentVoteGetOwn; request: VoteApi.CommentVoteGetOwnRequest; };
}
export interface commentVoteGetOwnActionRejected {
  type: commentVoteGetOwnActionStatus.Rejected;
  meta: { action: Action.commentVoteGetOwn; request: VoteApi.CommentVoteGetOwnRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum commentVoteUpdateActionStatus {
  Pending = 'commentVoteUpdate_PENDING',
  Fulfilled = 'commentVoteUpdate_FULFILLED',
  Rejected = 'commentVoteUpdate_REJECTED',
}
export interface commentVoteUpdateActionFulfilled {
  type: commentVoteUpdateActionStatus.Fulfilled;
  meta: { action: Action.commentVoteUpdate; request: VoteApi.CommentVoteUpdateRequest; };
  payload: Model.CommentVoteUpdateResponse;
}
export interface commentVoteUpdateActionPending {
  type: commentVoteUpdateActionStatus.Pending;
  meta: { action: Action.commentVoteUpdate; request: VoteApi.CommentVoteUpdateRequest; };
}
export interface commentVoteUpdateActionRejected {
  type: commentVoteUpdateActionStatus.Rejected;
  meta: { action: Action.commentVoteUpdate; request: VoteApi.CommentVoteUpdateRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaVoteGetOwnActionStatus {
  Pending = 'ideaVoteGetOwn_PENDING',
  Fulfilled = 'ideaVoteGetOwn_FULFILLED',
  Rejected = 'ideaVoteGetOwn_REJECTED',
}
export interface ideaVoteGetOwnActionFulfilled {
  type: ideaVoteGetOwnActionStatus.Fulfilled;
  meta: { action: Action.ideaVoteGetOwn; request: VoteApi.IdeaVoteGetOwnRequest; };
  payload: Model.IdeaVoteGetOwnResponse;
}
export interface ideaVoteGetOwnActionPending {
  type: ideaVoteGetOwnActionStatus.Pending;
  meta: { action: Action.ideaVoteGetOwn; request: VoteApi.IdeaVoteGetOwnRequest; };
}
export interface ideaVoteGetOwnActionRejected {
  type: ideaVoteGetOwnActionStatus.Rejected;
  meta: { action: Action.ideaVoteGetOwn; request: VoteApi.IdeaVoteGetOwnRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export enum ideaVoteUpdateActionStatus {
  Pending = 'ideaVoteUpdate_PENDING',
  Fulfilled = 'ideaVoteUpdate_FULFILLED',
  Rejected = 'ideaVoteUpdate_REJECTED',
}
export interface ideaVoteUpdateActionFulfilled {
  type: ideaVoteUpdateActionStatus.Fulfilled;
  meta: { action: Action.ideaVoteUpdate; request: VoteApi.IdeaVoteUpdateRequest; };
  payload: Model.IdeaVoteUpdateResponse;
}
export interface ideaVoteUpdateActionPending {
  type: ideaVoteUpdateActionStatus.Pending;
  meta: { action: Action.ideaVoteUpdate; request: VoteApi.IdeaVoteUpdateRequest; };
}
export interface ideaVoteUpdateActionRejected {
  type: ideaVoteUpdateActionStatus.Rejected;
  meta: { action: Action.ideaVoteUpdate; request: VoteApi.IdeaVoteUpdateRequest; };
  error: true;
  payload: {
    userFacingMessage?:string
  };
}

export class Dispatcher implements ApiInterface {
  readonly apiDelegate:Api;
  readonly dispatcherDelegate:(msg:any)=>Promise<any>;
  constructor(dispatcherDelegate:(msg:any)=>Promise<any>, apiDelegate:Api) {
    this.dispatcherDelegate = dispatcherDelegate;
    this.apiDelegate = apiDelegate || Api;
  }

  commentCreate(request: CommentApi.CommentCreateRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.CommentWithVote> {
    return this.dispatcherDelegate({
      type: Action.commentCreate,
      meta: {
        ...metaExtra,
        action: Action.commentCreate,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentCreate(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentApi().commentCreate(request, headerExtra),
    });
  }

  commentDelete(request: CommentApi.CommentDeleteRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.Comment> {
    return this.dispatcherDelegate({
      type: Action.commentDelete,
      meta: {
        ...metaExtra,
        action: Action.commentDelete,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentDelete(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentApi().commentDelete(request, headerExtra),
    });
  }

  commentSearch(request: CommentApi.CommentSearchRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.CommentSearchResponse> {
    return this.dispatcherDelegate({
      type: Action.commentSearch,
      meta: {
        ...metaExtra,
        action: Action.commentSearch,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentSearch(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentApi().commentSearch(request, headerExtra),
    });
  }

  commentUpdate(request: CommentApi.CommentUpdateRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.Comment> {
    return this.dispatcherDelegate({
      type: Action.commentUpdate,
      meta: {
        ...metaExtra,
        action: Action.commentUpdate,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentUpdate(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentApi().commentUpdate(request, headerExtra),
    });
  }

  ideaCommentSearch(request: CommentApi.IdeaCommentSearchRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaCommentSearchResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaCommentSearch,
      meta: {
        ...metaExtra,
        action: Action.ideaCommentSearch,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaCommentSearch(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCommentApi().ideaCommentSearch(request, headerExtra),
    });
  }

  contentUpload(request: ContentApi.ContentUploadRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ContentUploadResponse> {
    return this.dispatcherDelegate({
      type: Action.contentUpload,
      meta: {
        ...metaExtra,
        action: Action.contentUpload,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.contentUpload(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getContentApi().contentUpload(request, headerExtra),
    });
  }

  transactionSearch(request: CreditApi.TransactionSearchRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.TransactionSearchResponse> {
    return this.dispatcherDelegate({
      type: Action.transactionSearch,
      meta: {
        ...metaExtra,
        action: Action.transactionSearch,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.transactionSearch(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getCreditApi().transactionSearch(request, headerExtra),
    });
  }

  ideaCreate(request: IdeaApi.IdeaCreateRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaWithVote> {
    return this.dispatcherDelegate({
      type: Action.ideaCreate,
      meta: {
        ...metaExtra,
        action: Action.ideaCreate,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaCreate(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaApi().ideaCreate(request, headerExtra),
    });
  }

  ideaDelete(request: IdeaApi.IdeaDeleteRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.ideaDelete,
      meta: {
        ...metaExtra,
        action: Action.ideaDelete,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaDelete(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaApi().ideaDelete(request, headerExtra),
    });
  }

  ideaGet(request: IdeaApi.IdeaGetRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaWithVote> {
    return this.dispatcherDelegate({
      type: Action.ideaGet,
      meta: {
        ...metaExtra,
        action: Action.ideaGet,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaGet(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaApi().ideaGet(request, headerExtra),
    });
  }

  ideaGetAll(request: IdeaApi.IdeaGetAllRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaGetAllResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaGetAll,
      meta: {
        ...metaExtra,
        action: Action.ideaGetAll,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaGetAll(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaApi().ideaGetAll(request, headerExtra),
    });
  }

  ideaMerge(request: IdeaApi.IdeaMergeRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaConnectResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaMerge,
      meta: {
        ...metaExtra,
        action: Action.ideaMerge,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaMerge(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaApi().ideaMerge(request, headerExtra),
    });
  }

  ideaSearch(request: IdeaApi.IdeaSearchRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaWithVoteSearchResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaSearch,
      meta: {
        ...metaExtra,
        action: Action.ideaSearch,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaSearch(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaApi().ideaSearch(request, headerExtra),
    });
  }

  ideaUpdate(request: IdeaApi.IdeaUpdateRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.Idea> {
    return this.dispatcherDelegate({
      type: Action.ideaUpdate,
      meta: {
        ...metaExtra,
        action: Action.ideaUpdate,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaUpdate(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getIdeaApi().ideaUpdate(request, headerExtra),
    });
  }

  notificationClear(request: NotificationApi.NotificationClearRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.notificationClear,
      meta: {
        ...metaExtra,
        action: Action.notificationClear,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.notificationClear(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getNotificationApi().notificationClear(request, headerExtra),
    });
  }

  notificationClearAll(request: NotificationApi.NotificationClearAllRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.notificationClearAll,
      meta: {
        ...metaExtra,
        action: Action.notificationClearAll,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.notificationClearAll(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getNotificationApi().notificationClearAll(request, headerExtra),
    });
  }

  notificationSearch(request: NotificationApi.NotificationSearchRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.NotificationSearchResponse> {
    return this.dispatcherDelegate({
      type: Action.notificationSearch,
      meta: {
        ...metaExtra,
        action: Action.notificationSearch,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.notificationSearch(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getNotificationApi().notificationSearch(request, headerExtra),
    });
  }

  configAndUserBindSlug(request: ProjectApi.ConfigAndUserBindSlugRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ConfigAndUserBindSlugResult> {
    return this.dispatcherDelegate({
      type: Action.configAndUserBindSlug,
      meta: {
        ...metaExtra,
        action: Action.configAndUserBindSlug,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.configAndUserBindSlug(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectApi().configAndUserBindSlug(request, headerExtra),
    });
  }

  configBindSlug(request: ProjectApi.ConfigBindSlugRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.ConfigBindSlugResult> {
    return this.dispatcherDelegate({
      type: Action.configBindSlug,
      meta: {
        ...metaExtra,
        action: Action.configBindSlug,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.configBindSlug(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectApi().configBindSlug(request, headerExtra),
    });
  }

  userBindSlug(request: ProjectApi.UserBindSlugRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserBindResponse> {
    return this.dispatcherDelegate({
      type: Action.userBindSlug,
      meta: {
        ...metaExtra,
        action: Action.userBindSlug,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userBindSlug(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getProjectApi().userBindSlug(request, headerExtra),
    });
  }

  forgotPassword(request: UserApi.ForgotPasswordRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.forgotPassword,
      meta: {
        ...metaExtra,
        action: Action.forgotPassword,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.forgotPassword(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserApi().forgotPassword(request, headerExtra),
    });
  }

  userBind(request: UserApi.UserBindRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserBindResponse> {
    return this.dispatcherDelegate({
      type: Action.userBind,
      meta: {
        ...metaExtra,
        action: Action.userBind,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userBind(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserApi().userBind(request, headerExtra),
    });
  }

  userCreate(request: UserApi.UserCreateRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserCreateResponse> {
    return this.dispatcherDelegate({
      type: Action.userCreate,
      meta: {
        ...metaExtra,
        action: Action.userCreate,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userCreate(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserApi().userCreate(request, headerExtra),
    });
  }

  userDelete(request: UserApi.UserDeleteRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.userDelete,
      meta: {
        ...metaExtra,
        action: Action.userDelete,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userDelete(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserApi().userDelete(request, headerExtra),
    });
  }

  userGet(request: UserApi.UserGetRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.User> {
    return this.dispatcherDelegate({
      type: Action.userGet,
      meta: {
        ...metaExtra,
        action: Action.userGet,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userGet(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserApi().userGet(request, headerExtra),
    });
  }

  userLogin(request: UserApi.UserLoginRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserMeWithBalance> {
    return this.dispatcherDelegate({
      type: Action.userLogin,
      meta: {
        ...metaExtra,
        action: Action.userLogin,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userLogin(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserApi().userLogin(request, headerExtra),
    });
  }

  userLogout(request: UserApi.UserLogoutRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<void> {
    return this.dispatcherDelegate({
      type: Action.userLogout,
      meta: {
        ...metaExtra,
        action: Action.userLogout,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userLogout(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserApi().userLogout(request, headerExtra),
    });
  }

  userUpdate(request: UserApi.UserUpdateRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserMe> {
    return this.dispatcherDelegate({
      type: Action.userUpdate,
      meta: {
        ...metaExtra,
        action: Action.userUpdate,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.userUpdate(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getUserApi().userUpdate(request, headerExtra),
    });
  }

  categorySubscribe(request: VoteApi.CategorySubscribeRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.UserMe> {
    return this.dispatcherDelegate({
      type: Action.categorySubscribe,
      meta: {
        ...metaExtra,
        action: Action.categorySubscribe,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.categorySubscribe(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getVoteApi().categorySubscribe(request, headerExtra),
    });
  }

  commentVoteGetOwn(request: VoteApi.CommentVoteGetOwnRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.CommentVoteGetOwnResponse> {
    return this.dispatcherDelegate({
      type: Action.commentVoteGetOwn,
      meta: {
        ...metaExtra,
        action: Action.commentVoteGetOwn,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentVoteGetOwn(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getVoteApi().commentVoteGetOwn(request, headerExtra),
    });
  }

  commentVoteUpdate(request: VoteApi.CommentVoteUpdateRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.CommentVoteUpdateResponse> {
    return this.dispatcherDelegate({
      type: Action.commentVoteUpdate,
      meta: {
        ...metaExtra,
        action: Action.commentVoteUpdate,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.commentVoteUpdate(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getVoteApi().commentVoteUpdate(request, headerExtra),
    });
  }

  ideaVoteGetOwn(request: VoteApi.IdeaVoteGetOwnRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaVoteGetOwnResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaVoteGetOwn,
      meta: {
        ...metaExtra,
        action: Action.ideaVoteGetOwn,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaVoteGetOwn(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getVoteApi().ideaVoteGetOwn(request, headerExtra),
    });
  }

  ideaVoteUpdate(request: VoteApi.IdeaVoteUpdateRequest, metaExtra?:any, headerExtra?:{[key:string]:string}): Promise<Model.IdeaVoteUpdateResponse> {
    return this.dispatcherDelegate({
      type: Action.ideaVoteUpdate,
      meta: {
        ...metaExtra,
        action: Action.ideaVoteUpdate,
        request: request,
        retry: (headerExtraOverride?:{[key:string]:string}) => this.ideaVoteUpdate(request, metaExtra, headerExtraOverride || headerExtra),
      },
      payload: this.apiDelegate.getVoteApi().ideaVoteUpdate(request, headerExtra),
    });
  }
}
